import React from 'react';
import Welcome from '../components/patient/Welcome';
// import SpecialNotes from '../components/patient/SpecialNotes';
import PatientList from '../components/patient/PatientList';
import { Container } from '@mui/material';
import PatientInfo from '../components/patient/PatientInfo';
import Clinics from '../components/patient/Clinics';
import Notes from '../components/RightSidebar/Notes';
// import Information from '../components/patient/Information';

function OpdContainer() {
  return (
    <>
      <Clinics />
      {/* <Welcome /> */}
      <Container>
        <PatientInfo />
        {/* <Information /> */}
      </Container>

    </>
  );
}

export default PatientList(OpdContainer);