import React from "react";
import Welcome from "../../components/patient/Welcome";
import Information from "../../components/patient/Information";
import Message from "../../components/RightSidebar/Messge";
import PatientList from "../../components/patient/PatientList";

import { Container } from "@mui/material";
function MessageContainer() {
    return (
        <>
            {/* <Welcome /> */}
            <Information />
            <Container maxWidth='lg'>
                <Message />
            </Container>
        </>
    )
}
export default PatientList(MessageContainer);