import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { palette, } from "src/config/theme";
import { Typography } from "@mui/material";

export const FormBox = styled(Box)({
    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '86%',
    borderRadius: '16px', position: 'relative', right: '2.5%'


})
export const PatientBox = styled(Box)({
    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '86%',
    borderRadius: '16px',
    position: 'relative',
    bottom: '20px', right: '2.5%'

})
export const ViewBox = styled(Box)({

    // border: '1px solid #B7B7B7',
    // height: '5vh',
    // width: 'auto',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 30,
    // flexDirection: 'row'

})
export const PaymentBox = styled(Box)({

    border: '1px solid #D3D3D3',
    height: '15vh',
    width: '92%',
    borderRadius: 5,
    position: 'relative',
    top: '5px',
    left: '4%'


})
export const ClinicBox = styled(Box)({
    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '86%',
    borderRadius: '16px',
    position: 'relative',
    bottom: '40px', right: '2.5%'

})
export const ProfileBox = styled(Box)({
    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '82%',
    borderRadius: '16px',
    position: 'relative',
    bottom: '40px'
})

