import React, { useState, useEffect } from 'react';
import { Country, State, City } from 'country-state-city';
import { ClinicBox } from '../RightSidebar/style';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Stack,
  IconButton,
  Snackbar,
  Alert,
  Box,
  Divider,
  TextField,
  FormControlLabel,
  Checkbox,
  Button as MuiButton,
  Tabs,
  Tab,
  AppBar,
} from '@mui/material';
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { AddCircle, RemoveCircle } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Button } from 'src/components/shared';
import { useForm, Controller } from 'react-hook-form';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemIcon } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import InfoIcon from '@mui/icons-material/Info';
import InputComponent from 'src/components/shared/Form/Input';
import AppTimePicker from 'src/components/shared/Form/Timepicker';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'; 
import { useNavigate } from 'react-router-dom';



const doctors = [
  { id: 'doc1', name: 'Doctor 1' },
  { id: 'doc2', name: 'Doctor 2' },
  { id: 'doc3', name: 'Doctor 3' },
];

function Clinics() {
  const { control, handleSubmit } = useForm();
  const [selectedTab, setSelectedTab] = useState(0);
  const [openingHours, setOpeningHours] = useState([
    { day: '', hours: '', startTime: null, endTime: null , error: "" },
  ]);
  const [images, setImages] = useState([]);
  const [clinicInfo, setClinicInfo] = useState({
    name: '',
    title: '',
    tagLine: '',
    address: '',
    contact: '',
    email: '',
    registration: '',
    city: '',
    state: '',
    country: '',
    postCode: '',
    description: '',
  });

  // const [cmsInfo ,setcmsInfo] =useState([]);
  const [settings, setSettings] = useState({ tokens: false, appointments: false });
  const [specialNotes, setSpecialNotes] = useState({ notes: '', afterHoursNotes: '' });
  const [credentials, setCredentials] = useState({ achievements: '', awards: '' });
  const [socialLinks, setSocialLinks] = useState({
    website: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    twitter: '',
  });

  const [selectedTokens, setSelectedTokens] = useState([]);
  const [selectedAutomaticTasks, setSelectedAutomaticTasks] = useState([]);
  const [selectedAppointmentTime, setSelectedAppointmentTime] = useState([]);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [taskMedicineReminder, setTaskMedicineReminder] = useState(false);
  const [taskTreatmentScheduling, setTaskTreatmentScheduling] = useState(false);
  const [taskNewCaseFollowUp, setTaskNewCaseFollowUp] = useState(false);
  const [acceptingPatientsFeedback, setAcceptingPatientsFeedback] = useState(false);
  const [acceptingPatientsReview, setAcceptingPatientsReview] = useState(false);
  const [cmsInfo, setCmsInfo] = useState({
    title: '',
    description: '',
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();


  const handleSaveCms = () => {
    try {
      // Save logic here
      // Example: await api.saveCmsDetails(cmsInfo);

      // Show popup message
      setOpenSnackbar(true);

      setCmsInfo({
        title: '',
        description: '',
      });

      setSelectedTab(0);
    } catch (error) {
      console.error("Error saving CMS details:", error);
    }
  };


  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCmsInfo({ ...cmsInfo, [name]: value });
  };

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedState('');
    setSelectedCity('');
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedCity('');
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const countries = Country.getAllCountries();
  const states = selectedCountry ? State.getStatesOfCountry(selectedCountry) : [];
  const cities = selectedState ? City.getCitiesOfState(selectedCountry, selectedState) : [];

  const [services, setServices] = useState([{ name: '', value: '' }]);
  const [facilities, setFacilities] = useState([{ name: '', value: '' }]);

  const handleServiceChange = (index, field, value) => {
    const newServices = [...services];
    newServices[index][field] = value;
    setServices(newServices);
  };

  const handleFacilityChange = (index, field, value) => {
    const newFacilities = [...facilities];
    newFacilities[index][field] = value;
    setFacilities(newFacilities);
  };

  const handleAddService = () => {
    setServices([...services, { name: '', value: '' }]);
  };

  const handleAddFacility = () => {
    setFacilities([...facilities, { name: '', value: '' }]);
  };

  const handleRemoveService = (index) => {
    const newServices = services.filter((_, i) => i !== index);
    setServices(newServices);
  };

  const handleRemoveFacility = (index) => {
    const newFacilities = facilities.filter((_, i) => i !== index);
    setFacilities(newFacilities);
  };

  const [messageInfo, setMessageInfo] = useState({
    templateName: '',
    details: '',
  });
  const [establishmentYear, setEstablishmentYear] = useState('');

  const handleEstablishmentYearChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setEstablishmentYear(value);
  };

  const [consultingFees, setConsultingFees] = useState('');
  const [oldCaseConsultingFees, setOldCaseConsultingFees] = useState('');

  const handleConsultingFeesChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setConsultingFees(value);
  };

  const handleOldCaseConsultingFeesChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setOldCaseConsultingFees(value);
  };
  const [caseValidity, setCaseValidity] = useState('');
  const [emergencyFees, setEmergencyFees] = useState('');

  const handleCaseValidityChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setCaseValidity(value);
  };

  const handleEmergencyFeesChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setEmergencyFees(value);
  };

  const handleTokenChange = (event) => {
    setSelectedTokens(event.target.value);
  };
  const handleAppointmentsChange = (event) => {
    setSelectedAppointmentTime(event.target.value);
  };
  const handleSelectChange = (field) => (event) => {
    setClinicInfo({ ...clinicInfo, [field]: event.target.value });
  };
  const handleAutomaticTaskChange = (event) => {
    setSelectedAutomaticTasks(event.target.value);
  };

  const handleDoctorsChange = (event) => {
    setSelectedDoctors(event.target.value);
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [linkForms, setLinkForms] = useState([{ linkTitle: '', link: '' }]);

  const handleAddLinkForm = () => {
    setLinkForms([...linkForms, { linkTitle: '', link: '' }]);
  };

  const handleRemoveLinkForm = (index) => {
    const newLinkForms = linkForms.filter((_, i) => i !== index);
    setLinkForms(newLinkForms);
  };

  const onSubmit = (data) => {
    console.log(data);
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const newImages = files.map(file => URL.createObjectURL(file));
    setImages(prevImages => [...prevImages, ...newImages]);
  };

  const handleDelete = (index) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  // const handleAddOpeningHour = () => {
  //     setOpeningHours([...openingHours, { day: '', hours: '' }]);
  // };

  // const handleRemoveOpeningHour = (index) => {
  //     const newOpeningHours = [...openingHours];
  //     newOpeningHours.splice(index, 1);
  //     setOpeningHours(newOpeningHours);
  // };

  // const handleOpeningHourChange = (index, field, value) => {
  //     const newOpeningHours = [...openingHours];
  //     newOpeningHours[index][field] = value;
  //     setOpeningHours(newOpeningHours);
  // };

  const handleNext = () => {
    setSelectedTab(selectedTab + 1);
  };
  const handlePrevious = () => {
    setSelectedTab(selectedTab - 1);
  };

  const handleOpeningHourChange = (index, field, value) => {
    const updatedHours = [...openingHours];
    updatedHours[index][field] = value;

    // Time validation: End time should be after start time
    if (
      field === 'endTime' &&
      updatedHours[index].startTime &&
      value < updatedHours[index].startTime
    ) {
      updatedHours[index].error = 'End time must be after start time.';
    } else {
      updatedHours[index].error = '';
    }

    setOpeningHours(updatedHours);
  };

  // const handleOpeningHourChange = (index, field, value) => {
  //   const updatedHours = [...openingHours];
  //   updatedHours[index][field] = value;
  //   setOpeningHours(updatedHours);
  // };

  // Function to add a new opening hour entry
  const handleAddOpeningHour = () => {
    if (openingHours.length < 7) {
      setOpeningHours([...openingHours, { day: '', startTime: '', endTime: '' }]);
    } else {
      console.log('You can only add up to 7 opening hours.');
    }
  };

  const isNextDisabled = () => {
    return (
      // openingHours.length < 7 ||
      openingHours.some((hour) => !hour.day || !hour.startTime || !hour.endTime) 
    );
  };
  // Function to remove an opening hour entry
  const handleRemoveOpeningHour = (index) => {
    const updatedHours = [...openingHours];
    updatedHours.splice(index, 1);
    setOpeningHours(updatedHours);
  };

  const handleAcceptingPatientsReviewChange = (e) => setAcceptingPatientsReview(e.target.checked);

  const [photoDetails, setPhotoDetails] = useState({
    clinicID: '',
    userID: '',
    title: '',
    type: '0',
  });
  const handlePhotoDetailChange = (key, value) => {
    setPhotoDetails({ ...photoDetails, [key]: value });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPopover, setCurrentPopover] = useState(null);
  const [title, setTitle] = useState('');
  const [prescription, setPrescription] = useState('');
  const [templates, setTemplates] = useState([]);

  const handleClick = (event, policy) => {
    setAnchorEl(event.currentTarget);
    setCurrentPopover(policy);
  };

  const handleClose = () => {
    console.log('handleClose called');
    setAnchorEl(null);
    setCurrentPopover(null);
  };
  const handleSavePrescription = () => {
    // Save logic here

    // Reset form data if needed
    setPrescriptionInfo({
      templateName: '',
      details: '',
    });

    // Close the popover
    handleClose();
  };
  const handleSaveMessage = () => {
    // Save logic here

    // Reset form data if needed
    setMessageInfo({
      templateName: '',
      details: '',
    });

    // Close the popover
    handleClose();
  };

  // const handleTokenChange = (event) => {
  //     setSelectedTokens(event.target.value);
  // };

  // const handleAutomaticTaskChange = (event) => {
  //     setSelectedAutomaticTasks(event.target.value);
  // };

  const handleTaskMedicineReminderChange = (event) => {
    setTaskMedicineReminder(event.target.checked);
  };

  const handleTaskTreatmentSchedulingChange = (event) => {
    setTaskTreatmentScheduling(event.target.checked);
  };

  const handleTaskNewCaseFollowUpChange = (event) => {
    setTaskNewCaseFollowUp(event.target.checked);
  };

  const handleAcceptingPatientsFeedbackChange = (event) => {
    setAcceptingPatientsFeedback(event.target.checked);
  };

  const handleAcceptingPatientsreview = (event) => {
    setAcceptingPatientsReview(event.target.checked);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const policies = [
    'Privacy Policy',
    'Cancellation Policy',
    'Refund Policy',
    'Terms & Conditions',
    'Consent for Profile Publication',
    'Terms and Conditions Agreement',
  ];
  const prescriptions = ['Fever ', 'Stomach Ache', 'Headache ', 'Diabetes  ', 'Hypertension  '];
  const messages = [
    'Welcome Template',
    'Follow Up Temaplates',
    'Thank you Template',
    'Greeting Template',
  ];
  const handleEditClick = () => {
    setEditFormOpen(true);
  };
  const handlePrescriptionChange = (event) => {
    setPrescription(event.target.value);
  };
  const [prescriptionInfo, setPrescriptionInfo] = useState({
    patientName: '',
    medication: '',
    dosage: '',
  });
  const [isNewTemplate, setIsNewTemplate] = useState(false);
  const handleAddOpeningTemplate = () => {
    // Assuming each template is an object with title and prescription
    const newTemplate = { title, prescription };
    setTemplates([...templates, newTemplate]);

    // Clear input fields after adding template
    setTitle('');
    setPrescription('');
  };
  const handleAddClick = () => {
    setEditFormOpen(true);
    setIsNewTemplate(true);
    setPrescriptionInfo({ templateName: '', details: '' });
  };
  //   const handleSave = () => {
  //     // Save logic here
  //     setEditFormOpen(false);
  //   };

  const handleCancel = () => {
    // Cancel logic here
    setTitle('');
    setPrescription('');
    setEditFormOpen(false);
  };
  return (
    <ClinicBox>
      <Typography
        variant="h3"
        color="#393A96"
        fontWeight="bold"
        textAlign="center"
        sx={{ position: 'relative', top: '15px' }}
      >
        Clinic Information
      </Typography>
      <Divider sx={{ width: '95%', marginTop: '20px', marginLeft: '15px' }} />
      <AppBar position="static" color="default">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Clinic Information" />
          <Tab label="Opening Hours" />
          <Tab label="Prescription Templates" />
          <Tab label="Message Templates" />
          <Tab label="Settings" />
          <Tab label="Services Offered" />
          <Tab label="Facilities" />
          <Tab label="Links" />
          <Tab label="Photos" />
          <Tab label="CMS" />
        </Tabs>
      </AppBar>

      {selectedTab === 0 && (
        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
          <Box component="form">
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
              <Grid item xs={6}>
                <Typography variant="h5" fontWeight="bold">
                  Clinic Name
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={clinicInfo.name}
                  onChange={(e) => setClinicInfo({ ...clinicInfo, name: e.target.value })}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5" fontWeight="bold">
                  Title
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={clinicInfo.title}
                  onChange={(e) => setClinicInfo({ ...clinicInfo, title: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  Tag Line
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={clinicInfo.tagLine}
                  onChange={(e) => setClinicInfo({ ...clinicInfo, tagLine: e.target.value })}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h5" fontWeight="bold">
                  Contact Number
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={clinicInfo.contact}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                    setClinicInfo({ ...clinicInfo, contact: value });
                  }}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5" fontWeight="bold">
                  Email Address
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={clinicInfo.email}
                  onChange={(e) => setClinicInfo({ ...clinicInfo, email: e.target.value })}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5" fontWeight="bold">
                  Registration Number
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={clinicInfo.registration}
                  onChange={(e) => setClinicInfo({ ...clinicInfo, registration: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  Address
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  value={clinicInfo.address}
                  onChange={(e) => setClinicInfo({ ...clinicInfo, address: e.target.value })}
                />
              </Grid>

              <Grid item xs={6}>
        <Typography variant="h5" fontWeight="bold">Country</Typography>
        <FormControl fullWidth variant="outlined">
          <InputLabel>Country</InputLabel>
          <Select value={selectedCountry} onChange={handleCountryChange} label="Country">
            {countries.map((country) => (
              <MenuItem key={country.isoCode} value={country.isoCode}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="h5" fontWeight="bold">State</Typography>
        <FormControl fullWidth variant="outlined" disabled={!selectedCountry}>
          <InputLabel>State</InputLabel>
          <Select value={selectedState} onChange={handleStateChange} label="State">
            {states.map((state) => (
              <MenuItem key={state.isoCode} value={state.isoCode}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <Typography variant="h5" fontWeight="bold">City</Typography>
        <FormControl fullWidth variant="outlined" disabled={!selectedState}>
          <InputLabel>City</InputLabel>
          <Select value={selectedCity} onChange={handleCityChange} label="City">
            {cities.map((city) => (
              <MenuItem key={city.name} value={city.name}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>


              <Grid item xs={6}>
                <Typography variant="h5" fontWeight="bold">
                  Post Code
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={clinicInfo.postCode}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits and limit to 6 characters
                    if (/^\d{0,6}$/.test(value)) {
                      setClinicInfo({ ...clinicInfo, postCode: value });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight="bold">
                  Description
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  value={clinicInfo.description}
                  onChange={(e) => setClinicInfo({ ...clinicInfo, description: e.target.value })}
                />
              </Grid>
              <Grid container spacing={2} justifyContent="end" sx={{ marginTop: '10px' }}>
                <Button variant="contained" onClick={handleNext}>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}

      {selectedTab === 1 && (
        <Grid container spacing={2} sx={{ padding: '30px' }}>
          <Typography variant="h5" fontWeight="bold">
            Opening Hours
          </Typography>
          <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
              {openingHours.map((openingHour, index) => (
                <Grid container spacing={2} key={index} sx={{ marginBottom: '15px' }}>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    sx={{
                      position: 'relative',
                      top: '15px',
                    }}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      Day
                    </Typography>
                    <Select
                      fullWidth
                      variant="outlined"
                      value={openingHour.day}
                      onChange={(e) => handleOpeningHourChange(index, 'day', e.target.value)}
                    >
                      <MenuItem value="Monday">Monday</MenuItem>
                      <MenuItem value="Tuesday">Tuesday</MenuItem>
                      <MenuItem value="Wednesday">Wednesday</MenuItem>
                      <MenuItem value="Thursday">Thursday</MenuItem>
                      <MenuItem value="Friday">Friday</MenuItem>
                      <MenuItem value="Saturday">Saturday</MenuItem>
                      <MenuItem value="Sunday">Sunday</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h6" fontWeight="bold">
                      Start Time
                    </Typography>
                    <AppTimePicker
                      value={openingHour.startTime}
                      onChange={(time) => handleOpeningHourChange(index, 'startTime', time)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h6" fontWeight="bold">
                      End Time
                    </Typography>
                    <AppTimePicker
                      value={openingHour.endTime}
                      onChange={(time) => handleOpeningHourChange(index, 'endTime', time)}
                    />
                  </Grid>
                  
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <IconButton onClick={() => handleRemoveOpeningHour(index)}>
                      <RemoveCircle />
                    </IconButton>
                  </Grid>
                  {openingHour.error && (
          <Grid item xs={12}>
            <Typography color="error">{openingHour.error}</Typography>
          </Grid>
        )}
                </Grid>
              ))}
            </Box>
            <Grid
              container
              spacing={2}
              sx={{
                position: 'relative',
                right: '100px',
              }}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  startIcon={<AddCircle />}
                  onClick={handleAddOpeningHour}
                  fullWidth
                  // disabled={openingHours.some(
                  //   (openingHour) => openingHour.error || !openingHour.startTime || !openingHour.endTime
                  // )}
                >
                  Add Opening Hour
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="flex-end" sx={{ marginTop: '20px' }}>
              <Grid item>
                <Button variant="contained" onClick={handlePrevious}>
                  Previous
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleNext} disabled={isNextDisabled()}>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {selectedTab === 2 && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={8}>
            <Box sx={{ padding: '30px' }}>
              <Box component="form">
                {editFormOpen ? (
                  <Box sx={{ padding: '20px' }}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5" fontWeight="bold">
                          {isNewTemplate ? 'Add New Template' : 'Edit Template'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5" fontWeight="bold">
                          Title
                        </Typography>
                        <FormControl fullWidth variant="outlined">
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={prescriptionInfo.templateName}
                            onChange={(e) =>
                              setPrescriptionInfo({
                                ...prescriptionInfo,
                                templateName: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5" fontWeight="bold">
                          Prescription
                        </Typography>
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          variant="outlined"
                          value={prescriptionInfo.details}
                          onChange={(e) =>
                            setPrescriptionInfo({ ...prescriptionInfo, details: e.target.value })
                          }
                        />
                      </Grid>
                      <Grid
                        container
                        spacing={8}
                        justifyContent="center"
                        sx={{ marginTop: '-30PX' }}
                      >
                        <Grid item>
                          <Button variant="contained" onClick={handleCancel}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="contained" onClick={handleSavePrescription}>
                            Save Template
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <>
                    <Button
                      startIcon={<AddCircle />}
                      onClick={handleAddClick}
                      sx={{ marginBottom: '20px' }}
                    >
                      Add New Template
                    </Button>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          {prescriptions.map((prescription) => (
                            <TableRow key={prescription}>
                              <TableCell component="th" scope="row">
                                <Typography variant="h5" fontSize="14px" fontWeight="bold">
                                  {prescription}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  aria-describedby={id}
                                  onClick={(event) => handleClick(event, prescription)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                {currentPopover === prescription && (
                                  <Popover
                                    id={id}
                                    open={Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    PaperProps={{
                                      sx: { width: 170 },
                                    }}
                                  >
                                    <List>
                                      <ListItem
                                        button
                                        onClick={() => handleEditClick(prescription)}
                                      >
                                        <ListItemIcon>
                                          <EditIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Edit" />
                                      </ListItem>
                                      <ListItem button>
                                        <ListItemIcon sx={{ color: 'error.main' }}>
                                          <DeleteIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Delete" />
                                      </ListItem>
                                    </List>
                                  </Popover>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}

      {selectedTab === 3 && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={8}>
            <Box sx={{ padding: '30px' }}>
              <Box component="form">
                {editFormOpen ? (
                  <Box sx={{ padding: '20px' }}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5" fontWeight="bold">
                          {isNewTemplate ? 'Add New Template' : 'Edit Template'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5" fontWeight="bold">
                          Title
                        </Typography>
                        <FormControl fullWidth variant="outlined">
                          <TextField
                            fullWidth
                            variant="outlined"
                            value={messageInfo.templateName}
                            onChange={(e) =>
                              setMessageInfo({ ...messageInfo, templateName: e.target.value })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5" fontWeight="bold">
                          Message
                        </Typography>
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          variant="outlined"
                          value={messageInfo.details}
                          onChange={(e) =>
                            setMessageInfo({ ...messageInfo, details: e.target.value })
                          }
                        />
                      </Grid>
                      <Grid
                        container
                        spacing={8}
                        justifyContent="center"
                        sx={{ marginTop: '-30PX' }}
                      >
                        <Grid item>
                          <Button variant="contained" onClick={handleCancel}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="contained" onClick={handleSaveMessage}>
                            Save Template
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <>
                    <Button
                      startIcon={<AddCircle />}
                      onClick={handleAddClick}
                      sx={{ marginBottom: '20px' }}
                    >
                      Add New Template
                    </Button>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          {messages.map((message) => (
                            <TableRow key={message}>
                              <TableCell component="th" scope="row">
                                <Typography variant="h5" fontSize="14px" fontWeight="bold">
                                  {message}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  aria-describedby={id}
                                  onClick={(event) => handleClick(event, message)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                {currentPopover === message && (
                                  <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                    PaperProps={{
                                      sx: { width: 170 },
                                    }}
                                  >
                                    <List>
                                      <ListItem button onClick={() => handleEditClick(message)}>
                                        <ListItemIcon>
                                          <EditIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Edit" />
                                      </ListItem>
                                      <ListItem button>
                                        <ListItemIcon sx={{ color: 'error.main' }}>
                                          <DeleteIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Delete" />
                                      </ListItem>
                                    </List>
                                  </Popover>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}

      {selectedTab === 4 && (
        <Grid container spacing={2} sx={{ padding: '30px' }}>
          <Box component="form">
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
              <Grid item xs={6}>
                <Typography variant="h6" fontWeight="bold">
                  Accepting Tokens{' '}
                </Typography>
                <Select fullWidth value={selectedTokens} onChange={handleTokenChange}>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="NA">NA</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sx={{ position: 'relative', top: '20px' }}>
                <Typography variant="h5" fontWeight="bold">
                  Create Follow-up Task
                </Typography>
              </Grid>

              <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={taskNewCaseFollowUp}
                        onChange={handleTaskNewCaseFollowUpChange}
                      />
                    }
                    label="Create Task on New Case FollowUp"
                  />
                  <FormControlLabel
                    sx={{ position: 'relative', left: '15px' }}
                    control={
                      <Checkbox
                        checked={acceptingPatientsReview}
                        onChange={handleAcceptingPatientsReviewChange}
                      />
                    }
                    label="Accepting Patient’s Review"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={taskTreatmentScheduling}
                        onChange={handleTaskTreatmentSchedulingChange}
                      />
                    }
                    label="Create Task on Treatment Scheduling"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={acceptingPatientsFeedback}
                        onChange={handleAcceptingPatientsFeedbackChange}
                      />
                    }
                    label="Accepting Patients Feedback"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={taskMedicineReminder}
                        onChange={handleTaskMedicineReminderChange}
                      />
                    }
                    label="Create Task for Medicine Reminder"
                  />
                </Grid>
              </Grid>
              {/* <Grid item >
                                <Typography variant="h6" fontWeight="bold">Gap between Two Appointments</Typography>
                                <Select
                                    fullWidth
                                    value={selectedAppointmentTime}
                                    onChange={handleAppointmentsChange}
                                >
                                    <MenuItem value="10 min">10 Min</MenuItem>
                                    <MenuItem value="10 min">20 Min</MenuItem>
                                    <MenuItem value="30 min">30 Man</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item >
                                <Typography variant="h6" fontWeight="bold"></Typography>
                                <Select
                                    fullWidth
                                    value={selectedAppointmentTime}
                                    onChange={handleAppointmentsChange}
                                >
                                    <MenuItem value="10 min">10 Min</MenuItem>
                                    <MenuItem value="10 min">20 Min</MenuItem>
                                    <MenuItem value="30 min">30 Man</MenuItem>
                                </Select>
                            </Grid> */}
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold">
                  Automatic Task Assign to
                </Typography>
                <Select
                  fullWidth
                  value={selectedAutomaticTasks}
                  onChange={handleAutomaticTaskChange}
                  multiple
                  sx={{ width: '100%' }}
                >
                  <MenuItem value="Staff1">Staff 1</MenuItem>
                  <MenuItem value="Staff2">Staff 2</MenuItem>
                  {doctors.map((doctor) => (
                    <MenuItem key={doctor.id} value={doctor.id}>
                      {doctor.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold">
                  Associated Doctors
                </Typography>
                <Select
                  fullWidth
                  value={selectedDoctors}
                  onChange={handleDoctorsChange}
                  multiple
                  sx={{ width: '100%' }}
                >
                  {doctors.map((doctor) => (
                    <MenuItem key={doctor.id} value={doctor.id}>
                      {doctor.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold">
                  Establishment Year
                </Typography>
                <TextField
                  fullWidth
                  value={establishmentYear}
                  onChange={handleEstablishmentYearChange}
                  InputProps={{
                    inputProps: {
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    },
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold">
                  Consulting Fees
                </Typography>
                <TextField
                  fullWidth
                  value={consultingFees}
                  onChange={handleConsultingFeesChange}
                  InputProps={{
                    inputProps: {
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    },
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold">
                  Old Case Consulting Fees
                </Typography>
                <TextField
                  fullWidth
                  value={oldCaseConsultingFees}
                  onChange={handleOldCaseConsultingFeesChange}
                  InputProps={{
                    inputProps: {
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    },
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold">
                  Case Validity in Days
                </Typography>
                <TextField
                  fullWidth
                  value={caseValidity}
                  onChange={handleCaseValidityChange}
                  InputProps={{
                    inputProps: {
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    },
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight="bold">
                  Emergency Fees
                </Typography>
                <TextField
                  fullWidth
                  value={emergencyFees}
                  onChange={handleEmergencyFeesChange}
                  InputProps={{
                    inputProps: {
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    },
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={8} justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" onClick={handlePrevious}>
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {selectedTab === 5 && (
        <Grid container spacing={2} sx={{ padding: '30px' }}>
          <Typography variant="h5" fontWeight="bold">
            Service
          </Typography>
          {services.map((service, index) => (
            <Grid container spacing={2} sx={{ padding: '10px' }} key={index}>
              <Grid item xs={3}>
                <Typography variant="h6" fontWeight="bold">
                  Name
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={service.name}
                  onChange={(e) => handleServiceChange(index, 'name', e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6" fontWeight="bold">
                  Value
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={service.value}
                  onChange={(e) => handleServiceChange(index, 'value', e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => handleRemoveService(index)}>
                  <RemoveCircle sx={{ position: 'relative', right: '5px', top: 20 }} />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button startIcon={<AddCircle />} onClick={handleAddService}>
            Add Service
          </Button>
          <Grid container spacing={8} justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" onClick={handlePrevious}>
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {selectedTab === 6 && (
        <Grid container spacing={2} sx={{ padding: '30px' }}>
          <Typography variant="h5" fontWeight="bold">
            Facilities
          </Typography>
          {facilities.map((facility, index) => (
            <Grid container spacing={2} sx={{ padding: '10px' }} key={index}>
              <Grid item xs={3}>
                <Typography variant="h6" fontWeight="bold">
                  Name
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={facility.name}
                  onChange={(e) => handleFacilityChange(index, 'name', e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6" fontWeight="bold">
                  Value
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={facility.value}
                  onChange={(e) => handleFacilityChange(index, 'value', e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => handleRemoveFacility(index)}>
                  <RemoveCircle sx={{ position: 'relative', right: '5px', top: '20px' }} />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button startIcon={<AddCircle />} onClick={handleAddFacility}>
            Add Facility
          </Button>
          <Grid container spacing={8} justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" onClick={handlePrevious}>
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleNext}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {selectedTab === 7 && (
        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
          {linkForms.map((form, index) => (
            <Box key={index} component="form" onSubmit={handleSubmit(onSubmit)}>
              <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Grid item xs={12}>
                  <Typography variant="h5" fontWeight="bold">
                    Link Title
                  </Typography>
                  <Controller
                    name={`linkTitle-${index}`}
                    control={control}
                    defaultValue={form.linkTitle}
                    render={({ field }) => <TextField {...field} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" fontWeight="bold">
                    Link
                  </Typography>
                  <Controller
                    name={`link-${index}`}
                    control={control}
                    defaultValue={form.link}
                    render={({ field }) => <TextField {...field} fullWidth />}
                  />
                </Grid>
                {index === linkForms.length - 1 && (
                  <Grid item xs={2}>
                    <Button startIcon={<AddCircle />} onClick={handleAddLinkForm}>
                      Add New Link
                    </Button>
                  </Grid>
                )}
                {index > 0 && (
                  <Grid item xs={2}>
                    <Button startIcon={<AddCircle />} onClick={() => handleRemoveLinkForm(index)}>
                      Remove Link
                    </Button>
                  </Grid>
                )}
              </Grid>
              {index === linkForms.length - 1 && (
                <Grid container spacing={8} justifyContent="flex-end">
                  <Grid item>
                    <Button variant="contained" onClick={handlePrevious}>
                      Previous
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={handleNext}>
                      Next
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Box>
          ))}
        </Grid>
      )}

      {selectedTab === 8 && (
        <Grid container spacing={2} sx={{ padding: '30px' }}>
          {/* <Typography variant="h5" fontWeight="bold">Photos</Typography> */}
          <Grid item xs={12}>
          <Button variant="contained" component="label">
          Upload Photos
          <input hidden accept="image/*" multiple type="file" onChange={handleImageUpload} />
        </Button>
        <ImageList cols={3}>
          {images.map((image, index) => (
            <ImageListItem key={index} sx={{ position: 'relative' }}>
              <img src={image} alt={`uploaded-${index}`} />
              <IconButton
                onClick={() => handleDelete(index)}
                sx={{ position: 'absolute', top: 0, right: 0 }}
              >
                <DeleteIcon />
              </IconButton>
            </ImageListItem>
          ))}
        </ImageList>

            {/* <Grid item xs={12}>
                            <Typography variant="h6" fontWeight="bold">Photo Details</Typography>

                            <Select
                                fullWidth
                                variant="outlined"
                                value={photoDetails.type}
                                onChange={(e) => handlePhotoDetailChange('type', e.target.value)}
                                sx={{ marginBottom: '16px' }}
                            >
                                <MenuItem value="0">Normal</MenuItem>
                                <MenuItem value="1">Profile Pic</MenuItem>
                                <MenuItem value="2">Cover Photo</MenuItem>
                                <MenuItem value="3">Slider</MenuItem>
                                <MenuItem value="4">Other</MenuItem>
                            </Select>
                        </Grid> */}
            <Grid container spacing={8} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" onClick={handlePrevious}>
                  Previous
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleNext}>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {selectedTab === 9 && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={8}>
            <Box sx={{ padding: '30px' }}>
              <Box component="form">
                {editFormOpen ? (
                  <Box sx={{ padding: '20px' }}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5" fontWeight="bold">
                          Title
                        </Typography>
                        <FormControl fullWidth variant="outlined">
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="title"
                            value={cmsInfo.title}
                            onChange={handleInputChange}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h5" fontWeight="bold">
                          Description
                        </Typography>
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          variant="outlined"
                          name="description"
                          value={cmsInfo.description}
                          onChange={handleInputChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={4}
                      justifyContent="center"
                      sx={{ position: 'relative', top: '20px' }}
                    >
                      <Grid item>
                        <Button variant="contained" onClick={handleCancel}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                      <Button variant="contained" onClick={handleSaveCms}>
              Save Details
            </Button>

            {/* Snackbar for popup message */}
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
            >
              <Alert onClose={handleCloseSnackbar} severity="success">
                CMS Details Saved Successfully!
              </Alert>
            </Snackbar>
          </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        {policies.map((policy) => (
                          <TableRow key={policy}>
                            <TableCell component="th" scope="row">
                              <Typography variant="h5" fontSize="14px" fontWeight="bold">
                                {policy}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-describedby={id}
                                onClick={(event) => handleClick(event, policy)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              {currentPopover === policy && (
                                <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                  PaperProps={{
                                    sx: { width: 170 },
                                  }}
                                >
                                  <List>
                                    <ListItem button onClick={() => handleEditClick(policy)}>
                                      <ListItemIcon>
                                        <EditIcon />
                                      </ListItemIcon>
                                      <ListItemText primary="Edit" />
                                    </ListItem>
                                    <ListItem button>
                                      <ListItemIcon sx={{ color: 'error.main' }}>
                                        <DeleteIcon />
                                      </ListItemIcon>
                                      <ListItemText primary="Delete" />
                                    </ListItem>
                                  </List>
                                </Popover>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </ClinicBox>
  );
}

export default Clinics;
