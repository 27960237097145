import iconConfig from './Icons';

const itemList = [
  { label: 'Notes', icon: iconConfig['notes'], route: '/notes', permission: 'notes' },
  {
    label: 'Prescriptions',
    icon: iconConfig['prescription'],
    route: '/prescripation',
    permission: 'prescriptions',
  },
  { label: 'Follow Up', icon: iconConfig['followup'], route: '/followup', permission: 'followUp' },
  {
    label: 'Treatment',
    icon: iconConfig['treatment'],
    route: '/treatment',
    permission: 'treatment',
  },
  { label: 'Messages', icon: iconConfig['message'], route: '/message', permission: 'messages' },
  { label: 'Reports', icon: iconConfig['reports'], route: '/reports', permission: 'reports' },
  { label: 'Token', icon: iconConfig['token'], route: '/patienttoken', permission: 'token' },
  {
    label: 'Appointment',
    icon: iconConfig['appointment'],
    route: '/patientappointment',
    permission: 'appointment',
  },
  {
    label: 'New Patients',
    icon: iconConfig['newpatients'],
    route: '/patient',
    permission: 'patients',
  },
  { label: 'Case', icon: iconConfig['case'], route: '/case', permission: 'case' },
  { label: 'Billing', icon: iconConfig['bill'], route: '/bill', permission: 'billing' },
  { label: 'Task', icon: iconConfig['task'], route: '/task', permission: 'task' },
  { label: 'Area', icon: iconConfig['area'], route: '/area', permission: 'area' },
  { label: 'Tag', icon: iconConfig['notes'], route: '/tag', permission: 'tag' },
  {
    label: 'Patient Info',
    icon: iconConfig['patientinfo'],
    route: '/patientinfo',
    permission: 'patientInfo',
  },
  { label: 'Pharmacy', icon: iconConfig['pharmacy'], route: '/pharmacy', permission: 'pharmacy' },
  { label: 'Laboratory', icon: iconConfig['lab'], route: '/lab', permission: 'laboratory' },
  // { label: 'Clinic', icon: iconConfig['clinic'], route: '/clinic', permission: "clinic" },
  { label: 'Staffs', icon: iconConfig['staffs'], route: '/staff', permission: 'staff' },
];

export default itemList;
