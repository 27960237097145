import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Stack, CircularProgress, Box, Avatar } from '@mui/material';
import { fetchTokenAppointmentnameRequest, fetchPatientInfoRequest } from 'src/modules/app/store/appActions'; // Import fetchPatientInfoRequest
import { useNavigate } from 'react-router-dom';

const AllPatient = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const tokenAppointments = useSelector((state) => state.app.tokenAppointments);
  const clinic = useSelector((state) => state.app.clinics);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        await new Promise((resolve, reject) => {
          dispatch(fetchTokenAppointmentnameRequest(resolve, reject));
        });
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchAppointments();
  }, [dispatch]);

  const handlePatientClick = async (patient) => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}/notes/${patient.id}`, {
      state: { patient },
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" color="primary" textAlign="center" fontWeight="bold" mb={2} mt={2}>
        All Patients
      </Typography>

      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Typography variant="body1" color="error" textAlign="center" mt={2}>
          Error: {error.message}
        </Typography>
      )}

      {!isLoading && !error && (
        <Stack spacing={2}>
          {tokenAppointments.map((patient) => (
            <Box
              key={patient.id}
              onClick={() => handlePatientClick(patient)}
              sx={{
                p: 1,
                borderRadius: 2,
                cursor: 'pointer',
                backgroundColor: '#f5f5f5',
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <Avatar sx={{ mr: 2, bgcolor: '#9e9e9e' }}>{patient.name.slice(0, 1)}</Avatar>
              <Typography variant="h6" fontWeight="bold">
                {patient.name}
              </Typography>
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default AllPatient;