import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Typography, Grid, Tooltip, TextField, Select, MenuItem, Button } from '@mui/material';
import { Formik } from 'formik';
import { addTaskRequest } from '../../store/appActions';
import { FormBox } from './style';
import storage from 'src/utils/storageUtils';
import { jwtDecode } from 'jwt-decode';
import toast from 'src/utils/toast';
import { useNavigate, useLocation } from 'react-router-dom';


function Task() {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.isLoading);

  const location = useLocation();
  const navigate = useNavigate();
  const { patient } = location.state || {};

  const handleSubmit = async (values, { resetForm }) => {
    const token = storage.get('TOKEN');
    const decodedToken = jwtDecode(token);
    const user = decodedToken?.user;

    const task = { ...values, user };

    const promise = new Promise((resolve, reject) => {
      dispatch(addTaskRequest({ data: task, resolve, reject }));
    });

    try {
      const response = await promise;
      toast.success(response.message || 'Task added successfully');
      resetForm();
    } catch (error) {
      toast.error('Failed to add task: ' + (error.message || error));
      console.error('Failed to add task:', error.message || error);
    }
  };

  return (
    <FormBox>
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Formik
          initialValues={{
            name: '',
            assigned: '',
            dueDate: '',
            priority: '',
            caseID: '',
            tokenOrAppointment: '',
            description: '',
            status: 'pending',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Please enter the task name!'),
            assigned: Yup.string().required('Please enter the assignee!'),
            dueDate: Yup.date().required('Please select the due date!'),
            priority: Yup.string().required('Please select the priority!'),
            caseID: Yup.string().required('Please enter the case ID!'),
            tokenOrAppointment: Yup.string().required('Please enter the token or appointment details!'),
            description: Yup.string().required('Please enter the task description!'),
            status: Yup.string().required('Please select the status!'),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleSubmit, touched, errors, isValid }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Typography variant="h4" color="#393A96" fontWeight="bold" sx={{ marginLeft: '2%' }}>
                  Add Task
                </Typography>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">Task Name</Typography>
                  <Tooltip title="Enter the name of the task">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">Assigned To</Typography>
                  <Tooltip title="Enter the name of the person assigned to the task">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="assigned"
                      value={values.assigned}
                      onChange={handleChange}
                      error={touched.assigned && Boolean(errors.assigned)}
                      helperText={touched.assigned && errors.assigned}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">Due Date</Typography>
                  <Tooltip title="Select the due date of the task">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="dueDate"
                      type="date"
                      value={values.dueDate}
                      onChange={handleChange}
                      error={touched.dueDate && Boolean(errors.dueDate)}
                      helperText={touched.dueDate && errors.dueDate}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">Priority</Typography>
                  <Tooltip title="Select the priority of the task">
                    <Select
                      variant="outlined"
                      fullWidth
                      name="priority"
                      value={values.priority}
                      onChange={handleChange}
                      error={touched.priority && Boolean(errors.priority)}
                      helperText={touched.priority && errors.priority}
                    >
                      <MenuItem value="high">High</MenuItem>
                      <MenuItem value="medium">Medium</MenuItem>
                      <MenuItem value="low">Low</MenuItem>
                    </Select>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">Case ID</Typography>
                  <Tooltip title="Enter the case ID">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="caseID"
                      value={values.caseID}
                      onChange={handleChange}
                      error={touched.caseID && Boolean(errors.caseID)}
                      helperText={touched.caseID && errors.caseID}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">Token/Appointment</Typography>
                  <Tooltip title="Enter the token or appointment details">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="tokenOrAppointment"
                      value={values.tokenOrAppointment}
                      onChange={handleChange}
                      error={touched.tokenOrAppointment && Boolean(errors.tokenOrAppointment)}
                      helperText={touched.tokenOrAppointment && errors.tokenOrAppointment}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">Description</Typography>
                  <Tooltip title="Enter the task description">
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      error={touched.description && Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                      multiline
                      rows={4}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">Status</Typography>
                  <Tooltip title="Select the status of the task">
                    <Select
                      variant="outlined"
                      fullWidth
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      error={touched.status && Boolean(errors.status)}
                      helperText={touched.status && errors.status}
                    >
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="completed">Completed</MenuItem>
                      <MenuItem value="in-progress">In Progress</MenuItem>
                    </Select>
                  </Tooltip>
                </Grid>
                <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '30px' }}>
                  <Tooltip title="Add the task" arrow>
                    <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid}>
                      {isLoading ? 'Saving...' : 'Save Task'}
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </FormBox>
  );
}

export default Task;
