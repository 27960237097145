import React from 'react';
import Welcome from '../../components/patient/Welcome';
import Information from '../../components/patient/Information';
import Notes from '../../components/RightSidebar/Notes';
import { Container } from '@mui/material';
import PatientList from '../../components/patient/PatientList';
function NotesContainer() {
  return (
    <>
      {/* <Welcome /> */}
      <Information />

      <Container maxWidth="lg">
        <Notes />
      </Container>
    </>
  );
}
export default PatientList(NotesContainer);