import React, { useEffect, useState } from 'react';
import { PatientName, AddressName, InfoTypography, DataTypography, Time, TimeBox } from './styles';
import { Container, Grid, Stack, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from 'src/components/shared/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchTokenAppointmentnameRequest } from 'src/modules/app/store/appActions';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import InputComponent from '../../../../components/shared/Form/Input/index';
import InputSelect from 'src/components/shared/Form/Select';
import appActions from 'src/modules/app/store/appActions';

function Infomation() {
  const genderOption = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Other', value: 'other' },
  ];

  const BasicData = [
    { label: 'Headache issues', value: 'Headache Issue' },
    { label: 'Neurological issues', value: 'Neurological Issue' },
    { label: 'Endocrine Issues', value: 'Endocrine Issue' },
    { label: 'Infection Issues', value: 'Infection Issue' },
    { label: 'Fever', value: 'Fever' },
  ];

  const [tokenAppointmentDatas, setTokenAppointmentDatas] = useState('');
  const [editStates, setEditStates] = useState({});
  const [editedName, setEditedName] = useState('');
  const [editedAge, setEditedAge] = useState('');
  const [editedGender, setEditedGender] = useState('');
  const [editedBasic, setEditedBasic] = useState('');
  const [editedNumber, setEditedNumber] = useState('');
  const [editedWeight, setEditedWeight] = useState('');
  const [editedHeight, setEditedHeight] = useState('');
  const dispatch = useDispatch();

  const tokenAppointmentData = useSelector((state) => state.app.tokenAppointments);
  const patientInfoResponse = useSelector((state) => state.app.patientInfo) || [];
  const patientInfo = (Array.isArray(patientInfoResponse) && patientInfoResponse[0]) || {};
  const formatTime = (time) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(time).toLocaleTimeString(undefined, options);
  };

  useEffect(() => {
    if (tokenAppointmentData && Array.isArray(tokenAppointmentData)) {
      const latestRecords = tokenAppointmentData.slice(0, 10);
      console.log('Latest 10 Records:', latestRecords);
      setTokenAppointmentDatas(latestRecords);

      const initialEditStates = latestRecords.reduce((acc, _, index) => {
        acc[index] = false;
        return acc;
      }, {});
      setEditStates(initialEditStates);
    } else {
      console.error('Medical records is not an array or is undefined');
    }
  }, [tokenAppointmentData]);

  const handleEditClick = (index) => {
    setEditStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));

    if (!editStates[index]) {
      const record = tokenAppointmentDatas[index];
      setEditedName(record.name);
      setEditedAge(record.age);
      setEditedGender(record.gender);
      setEditedBasic(record.symptoms);
      setEditedNumber(record.mobileNo);
    }
  };
  const handleSaveClick = (index) => {
    const record = tokenAppointmentDatas[index];
    const patientInfoId =  patientInfo.id;
    const tokenAppointmentId = record.tokenappointment;
    const patientId = record.id;
   

    const patientData = {
      age: editedAge,
      fullName: editedName,
      gender: editedGender,
      contactNo: editedNumber,
    };

    // const InfoData = {
    //   weight: editedWeight,
    //   height: editedHeight,
    // };

    const tokenAppointmentData = {
      addnotes: editedBasic,
    };

    dispatch(appActions.updatePatientRequest(patientId, patientData));
    // dispatch(appActions.updatePatientInfoRequest(patientInfoId, InfoData));
    dispatch(appActions.updateTokenRequest(tokenAppointmentId, tokenAppointmentData));
    setEditStates((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  return (
    <>
      {tokenAppointmentDatas && tokenAppointmentDatas.length > 0 ? (
        tokenAppointmentDatas.map((appointment, index) => (
          <Box
            sx={{
              padding: '20px',
              width: '86%',
              backgroundColor: 'white',
              borderRadius: '16px',
              boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              bottom: '20px',
              right: '2.5%',
              justifyContent: 'space-between',
              marginTop: '15px',
              marginBottom: '10px',
            }}
          >
            <Grid container direction="column">
              <Box p={1} sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ marginRight: 2 }}>
                  {appointment.name ? appointment.name.charAt(0).toUpperCase() : 'A'}
                </Avatar>

                <Box sx={{ width: '200px' }}>
                  {editStates[index] ? (
                    <InputComponent
                      variant="outlined"
                      size="small"
                      value={editedName}
                      onChange={(e) => setEditedName(e.target.value)}
                      sx={{ width: '120px' }}

                    />
                  ) : (
                    <PatientName variant="h4">{appointment.name}</PatientName>
                  )}
                  {/* <AddressName variant="subtitle2">1338, Emily Flat, Reginaldtown</AddressName> */}
                </Box>
                <Box>
                  <Stack
                    direction={{ sm: 'row' }}
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                    // alignItems="center"
                    spacing={2}
                    position="relative"
                    // left="35%"
                  >
                    <Box>
                      <InfoTypography variant="body1">Age</InfoTypography>
                      {editStates[index] ? (
                        <InputComponent
                          variant="outlined"
                          size="small"
                          value={editedAge}
                          onChange={(e) => setEditedAge(e.target.value)}
                          sx={{ width: '60px' }}
                        />
                      ) : (
                        <DataTypography variant="body2">{appointment.age} Year</DataTypography>
                      )}
                    </Box>

                    <Box>
                      <InfoTypography variant="body1">Sex</InfoTypography>
                      {editStates[index] ? (
                          <InputSelect
                          options={genderOption}
                          onChange={(e) => setEditedGender(e.target.value)}
                          value={editedGender}
                          variant="outlined"
                          size="small"
                           />
                      ) : (
                        <DataTypography variant="body2">{appointment.gender}</DataTypography>

                      )}
                    </Box>
                    <Box>
                      <InfoTypography variant="body1">Contact No</InfoTypography>
                      {editStates[index] ? (
                        <InputComponent
                          variant="outlined"
                          size="small"
                          value={editedNumber}
                          onChange={(e) => setEditedNumber(e.target.value)}
                          sx={{ width: '100px' }}
                        />
                      ) : (
                        <DataTypography variant="body2">{appointment.mobileNo}</DataTypography>
                      )}
                    </Box>
                    {/* <Box>
                    <InfoTypography variant="body1">Width</InfoTypography>
                    </Box>
                    <Box>
                    <InfoTypography variant="body1">Height</InfoTypography>
                    </Box> */}

                    <Box>
                      <InfoTypography variant="body1">Basic</InfoTypography>
                      {editStates[index] ? (
                         <InputSelect
                         options={BasicData}
                         onChange={(e) => setEditedBasic(e.target.value)}
                         value={editedBasic}
                         variant="outlined"
                         size="small"
                          />
                        
                      ) : (
                        <DataTypography variant="body2">{appointment.symptoms}</DataTypography>

                      )}
                    </Box>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      if (editStates[index]) {
                        handleSaveClick(index);
                      } else {
                        handleEditClick(index);
                      }
                    }}
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                  >
                    {editStates[index] ? <SaveIcon /> : <EditIcon />}
                  </IconButton>
                </Box>
                <TimeBox>
                  <Time>{formatTime(appointment.time)}</Time>
                </TimeBox>
              </Box>
            </Grid>
          </Box>
        ))
      ) : (
        <Typography>No Data available</Typography>
      )}
    </>
  );
}

export default Infomation;
