import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { palette, } from "src/config/theme";
import { Avatar, Typography } from "@mui/material";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';

import theme from 'src/config/theme';

export const HandIcon = styled(Avatar)({
  position: 'relative',
  top: 30,
  left: 20
})

export const StyledBox = styled(Box)({
  backgroundColor: palette.primary.main,
  width: '86%',
  borderRadius: '16px', position: 'relative', bottom: "40px", right: '2.5%'
});
export const FormBox = styled(Box)({
  backgroundColor: palette.white,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
  width: '82%',
  borderRadius: '16px',
})
export const GreetingTypography = styled(Typography)({
  color: '#ffffff',
  position: 'relative',
  left: '65px',
  top: '0px',
  fontWeight: 'bold'
});

export const ClinicTypography = styled(Typography)({
  color: '#ffffff',
  position: 'relative',

  left: '5%',


});

export const SwitchContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '25%',
  height: '46.74px',
  position: 'relative',
  left: '69%',
  bottom: 40,
  border: '2px solid #ffffff',
  borderRadius: '4px',
});

export const CloseTypography = styled(Typography)({
  color: '#ffffff',

  position: 'relative',
  left: '10%'
});

export const OpenTypography = styled(Typography)({
  color: '#ffffff',
  marginRight: '10%',
  position: 'relative',
  left: '40%'
});
//PatientInfo.js

export const PatientName = styled(Typography)({
  color: palette.primary.main,
  fontWeight: 'bold',
})
export const AddressName = styled(Typography)({
  color: '#B7B7B7',

})
export const Time = styled(Typography)({
  color: '#FFFFFF',

})

export const TimeBox = styled(Box)({

  backgroundColor: palette.primary.main,
  display: 'flex',
  position: 'absolute',
  right: 0,
  bottom: 0,
  padding: 5,
  borderRadius: 10,
  margin: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80px'
});
// Notes.js

export const NotesBox = styled(Box)({
  width: '79.5%',
  borderRadius: '16px 0px 0px 0px',
  padding: '16px',
  gap: '8px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  bottom: '110px',
});
export const ViewBox = styled(Box)({

  border: '1px solid #B7B7B7',
  height: '6vh',
  width: '25%',
  borderRadius: 5,
  position: 'relative',
  top: '10px',
})
//  Action .js

export const StylesBox = styled(Box)({
  border: `2px solid ${theme.palette.gray}`,
  borderRadius: 8,
  width: '90%',
  height: '75%', position: 'relative',
  bottom: '100px'
});

export const StylesTypography = styled(Typography)({
  fontSize: '20px',
  fontWeight: '400',
  position: 'relative',
  bottom: 30,
  left: '20%'
});
export const StyleTypography = styled(Typography)({

  position: 'relative',
  bottom: 30,
  left: "20%"
});
export const PatientInfoBox = styled(Box)({
  backgroundColor: palette.white,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
  height: '20vh',
  width: '82%',
  borderRadius: '16px',
  position: 'relative',
  bottom: '20px'
})

export const InfoTypography = styled(Typography)({
  flex: 'end',
  justifyContent: 'flex-end',
  alignItems: 'center',
  color: palette.primary.main, position: 'relative', bottom: '5px'
})


export const DataTypography = styled(Typography)({

  color: '#B7B7B7',
  // position:'relative',
  // bottom:'20px', flex:'end',
  // justifyContent:'flex-end',
  // alignItems:'center',
})

export const PatientBox = styled(Box)({

  backgroundColor: palette.white,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
  height: '117%',
  width: '25%',
  borderRadius: '16px',
  position: 'relative',

});

export const PatientItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: palette.grey,
  borderRadius: '8px',
  padding: '4px',
  marginBottom: '8px',
});

export const PatientAvatar = styled(Avatar)({
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  marginRight: '16px'
})

export const Icon = styled(Avatar)({
  position: 'relation', left: 10, top: 15
})
export const StyledNotificationsIcon = styled(NotificationsActiveOutlinedIcon)({

  color: palette.primary.main,

})
